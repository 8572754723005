<template>
  <Usage :id="id" :endpoint="endpoint" :usage-type="usageTypes.EVALUATION" />
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useTexts from "@/composables/useTexts";
import useEvaluation from "@/composables/useEvaluation";
// Components
import Usage from "@/components/templates/Usage";
// Constants
import usageTypes from "@/constants/usageTypes";

export default {
  components: {
    Usage
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    resource: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();

    // Composables
    const { endpoint } = useEvaluation();
    const { getText } = useTexts();

    // Computed
    const documentTitle = computed(() => {
      // eslint-disable-next-line
      const title = `${t("app.usage")} - ${t("app.quizzes", 2)} - ${t("app.evaluations", 2)} - ${t("app.teaching")}`;

      const name = getText(props.resource?.texts, "name");
      if (name) {
        return `${name} - ${title}`;
      }

      return title;
    });

    return {
      documentTitle,
      usageTypes,
      // useEvaluation
      endpoint
    };
  }
};
</script>
